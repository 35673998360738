import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import ChangePassword from "@/views/ChangePassword";
import ChangePasswordSuccess from "@/views/ChangePasswordSuccess";
import Auth from "@/views/Auth";

import { AmplifyPlugin } from "aws-amplify-vue";
import * as AmplifyModules from "aws-amplify";
import UserStore from "../store/user";

Vue.use(VueRouter);
Vue.use(AmplifyPlugin, AmplifyModules);

const listener = async data => {
  switch (data.payload.event) {
    case "signIn":
      await getUser();
      router.push({ path: "/changePassword" }, () => { });
      break;
    case "signIn_failure":
      await getUser();
      router.push({ path: "/" }, () => { });
      break;
    // Below, the unused codes
    // case "signOut":
    // case "signUp":
    // case "tokenRefresh":
    // case "tokenRefresh_failure":
    // case "configured":
  }
};
AmplifyModules.Hub.listen("auth", listener);

// Define getUser function
async function getUser () {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(data => {
    if (data) {
      // When current authenticated user found, store it in local storage
      const userEmail = data.signInUserSession.idToken.payload.email;
      UserStore.commit("setUserEmail", userEmail);
      // Return user
      return data;
    } else {
      // By default, remove elements from local storage
      UserStore.commit("setUserEmail", null);
      return null;
    }
  }).catch(() => {
    // When current authenticated user not found, drop local storage
    UserStore.commit("setUserEmail", null);
    return null;
  })
}

// Define credentials expired function
async function isCredentialsExpired () {
  return Vue.prototype.$Amplify.Auth.currentCredentials().then(data => {
    if (data) {
      // Return expired attribute of credentials
      return data.expired;
    } else {
      // By default, consider credentials are expired
      return true;
    }
  }).catch(() => {
    // On error (no authenticated ?), consider credentials are expired
    return true;
  })
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/passwordChangedSuccess",
    name: "ChangePasswordSuccess",
    component: ChangePasswordSuccess,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  routes
})

// Firewall: if user is not authenticated when resolving routes, redirect him to /auth
router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  const expired = await isCredentialsExpired()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (expired || !user) {
      return next({
        path: "/auth",
        query: {
          redirect: to.fullPath
        }
      })
    }
    return next()
  }
  return next()
})

export default router
