<template>
  <div id="wrapper">
    <section class="hidden-previous">
      <header></header>
      <div class="content"></div>
    </section>

    <!-- Section -->
    <section id="first">
      <header>
        <h2>Completed</h2>
      </header>
      <div class="content">
        <div>
          <h1>Password changed &#10003;</h1>
          <h2>
            You will be disconnected and pushed back to homepage in
            <span>{{ disconnectCounter }}</span> seconds.
          </h2>
          <h2>
            <button v-on:click="disconnectAction">Disconnect now</button>
          </h2>
          <h3>Enjoy your new password &#9829;</h3>
        </div>
      </div>
    </section>

    <div class="copyright">
      &copy; Devoteam Revolve. All rights reserved. Design:
      <a href="https://html5up.net">HTML5 UP</a>.
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "ChangePasswordSuccess",
  data: function () {
    return {
      disconnectCounter: 10,
      disconnectCounterInterval: null,
    };
  },
  mounted: function () {
    const that = this;
    that.disconnectCounterInterval = setInterval(function () {
      that.disconnectCounter = that.disconnectCounter - 1;
      if (that.disconnectCounter <= 0) {
        that.disconnectAction();
      }
    }, 1000);
  },
  methods: {
    disconnectAction() {
      const that = this;
      clearInterval(that.disconnectCounterInterval);
      Auth.signOut()
        .then(() => {
          that.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          that.$router.push("/");
        });
    },
  },
};
</script>

<style scoped>
#wrapper {
  padding: 0;
  margin-top: -1rem;
  height: 100vh;
}
#wrapper section#first {
  margin-top: 0;
  margin: 7.5rem 0 0 0;
}

@media screen and (max-width: 1152px) {
  #wrapper {
    height: auto;
    overflow: auto;
    padding: 0;
    margin: 0;
  }
  #wrapper section.hidden-previous {
    display: none;
  }
  #wrapper section#first {
    margin: 0;
  }
}

</style>
