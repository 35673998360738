import { API } from 'aws-amplify'

/**
 * Utils service calling the api (useful if we need to add custom headers)
 */
class ApiService {
  async apiGet (path, queryParameters) {
    const params = {
      headers: {},
      queryStringParameters: queryParameters
    }
    return API.get('api', path, params)
  }

  async apiPut (path, bodyParameters) {
    const params = {
      headers: {},
      body: bodyParameters
    }
    return API.put('api', path, params)
  }

  async apiPost (path, bodyParameters) {
    const params = {
      headers: {},
      body: bodyParameters
    }
    return API.post('api', path, params)
  }

  async apiDelete (path) {
    const params = {
      headers: {}
    }
    return API.del('api', path, params)
  }
}

/**
 * Cloud endpoint: contains "functional" methods to call in components.
 * Useful for local testing: can be mocked.
 */
const apiService = new ApiService()
export default class CloudEndpointService {
  async getUser () {
    return apiService.apiGet('/user')
  }

  async postPassword (newPassword) {
    return apiService.apiPost('/user', {
        password: newPassword
    })
  }
}
