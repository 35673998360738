<template>
  <div id="wrapper">
    <section class="hidden-previous">
      <header></header>
      <div class="content"></div>
    </section>

    <!-- Section -->
    <section id="first">
      <header>
        <h2>Revolve Identity Federation</h2>
      </header>
      <div class="content">
        <h3>
          You are about to reset your
          <span style="font-weight: bold"
            >Revolve Active Directory password</span
          >. This password is not in sync with the Google password.
        </h3>
        <p>
          To reset your
          <strong>Revolve Active Directory password</strong>, you must prove
          your identity using your <strong>Revolve Google account</strong>.
          Click on
          <a style="cursor: pointer" v-on:click="signIn()"
            >Sign in with Google</a
          >
          to connect using your Google account.
        </p>
        <p style="text-align: center">
          &gt;&nbsp;&gt;&nbsp;&gt;&nbsp;&gt;&nbsp;
          <button class="primary" v-on:click="signIn()">
            Sign in with Google
          </button>
          &nbsp;&lt;&nbsp;&lt;&nbsp;&lt;&nbsp;&lt;
        </p>
        <h4>
          If lost your <span style="font-weight: bold">Google password</span>,
          please use the
          <a href="https://applis.devoteam.com/">Devoteam portal</a> to reset it
        </h4>
        <span class="image main">
          <img src="@/assets/images/cover-02.jpg" />
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "Auth",
  components: {},
  methods: {
    signIn: function () {
      // Initiate sign-in with hosted UI
      Auth.federatedSignIn().catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  padding: 0;
  margin-top: -7.5rem;
}
#wrapper > section#first > .content {
  height: 100vh;
  padding-top: 3rem;
}
#wrapper section#first {
  margin-top: 0;
  margin: 7.5rem 0 0 0;
}

@media screen and (max-width: 1152px) {
  #wrapper {
    overflow: auto;
    padding: 0;
    margin: 0;
  }
  #wrapper section.hidden-previous {
    display: none;
  }
  #wrapper > section#first > .content {
    height: auto;
  }
  #wrapper section#first {
    margin: 0;
  }
}
</style>
