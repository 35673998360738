import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const userStore = new Vuex.Store({
  state: {
    userEmail: null
  },
  mutations: {
    setUserEmail (state, userEmail) {
      state.userEmail = userEmail
    }
  }
})

export default userStore
