<template>
  <div id="wrapper">
    <section class="hidden-previous">
      <header></header>
      <div class="content"></div>
    </section>

    <!-- Section -->
    <section id="first">
      <header>
        <h2>Password change</h2>
      </header>
      <div class="content">
        <div v-if="user == null">Loading...</div>
        <div v-else class="form">
          <div class="fields">
            <div class="field half">
              <label for="demo-name">Login</label>
              <input disabled="disabled" type="text" v-model="user.login" />
            </div>
            <div class="field half">
              <label for="demo-email">Email</label>
              <input disabled="disabled" type="email" v-model="user.email" />
            </div>
            <div class="field">
              <label for="password">New password</label>
              <input type="password" value="" v-model="newPasswordInput" />
            </div>
          </div>
          <ul class="actions">
            <li>
              <button v-on:click="changePasswordAction" class="primary">
                Update password
              </button>
            </li>
            <li>
              <button v-on:click="cancelAction">Cancel</button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import CloudEndpointService from "@/services/cloud-endpoint";

const cloudEndpoint = new CloudEndpointService();

export default {
  name: "ChangePassword",
  data: function () {
    return {
      user: null,
      newPasswordInput: "",
    };
  },
  mounted: function () {
    this.retrieveUserFromApi();
  },
  methods: {
    retrieveUserFromApi() {
      const that = this;
      cloudEndpoint
        .getUser()
        .then((response) => {
          that.user = response;
        })
        .catch(err => {
          console.log(err);
        });
    },
    cancelAction() {
      const that = this;
      Auth.signOut()
        .then(() => {
          that.$router.push("/");
        })
        .catch(err => console.log(err));
    },
    changePasswordAction() {
      const that = this;
      cloudEndpoint
        .postPassword(this.newPasswordInput)
        .then(() => {
          that.$router.push("/passwordChangedSuccess")
        })
        .catch(err => {
          //
          // TODO: error managemnet from API (messages when invalid length for example ?)
          //
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  padding: 0;
  margin-top: -7.5rem;
}
#wrapper > section#first > .content {
  height: 100vh;
  padding-top: 3rem;
}
#wrapper section#first {
  margin-top: 0;
  margin: 7.5rem 0 0 0;
}

@media screen and (max-width: 1152px) {
  #wrapper {
    overflow: auto;
    padding: 0;
    margin: 0;
  }
  #wrapper section.hidden-previous {
    display: none;
  }
  #wrapper > section#first > .content {
    height: auto;
  }
  #wrapper section#first {
    margin: 0;
  }
}
</style>
