<template>
  <div id="wrapper">
    <section class="intro">
      <header>
        <h1>Revolve Password Reset</h1>
        <p>Password reset tool for Syges, Gitlab, Citrix, Planning</p>
        <p class="small-screen">
          <a
            v-on:click="$router.push('/changePassword')"
            class="arrow scrolly"
            href="#/changePassword"
            ><span class="label">Next</span></a
          >
        </p>
        <ul class="actions">
          <li>
            <a
              v-on:click="$router.push('/changePassword')"
              class="arrow scrolly"
              href="#/changePassword"
              ><span class="label">Next</span></a
            >
          </li>
        </ul>
      </header>
      <div class="content">
        <span class="image fill" data-position="center"
          ><img src="@/assets/images/cover-01.jpg" alt=""
        /></span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  padding: 0;
}
.intro {
  padding: 0;
}
.small-screen {
  display: none;
}
@media screen and (max-width: 1152px) {
  .small-screen {
    display: inline-block;
    height: 5rem;
  }
  .small-screen a {
    height: 5rem;
    width: auto;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
</style>
