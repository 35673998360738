<template>
  <div id="app">
    <div id="nav"></div>
    <router-view />
  </div>
</template>

<script>

export default {

}
</script>

<style>
@import './assets/css/main.css';
@import './assets/css/fontawesome-all.min.css'
</style>
